html {
  scroll-padding-top: 100px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

div#page {
  display: block;
  height: 100vh;
  width: 100%;
}

div#header {
  width: 100%;
}

div#content-scroll {
  overflow-y: auto;
  height: 100vh;
}

div#content {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sentenceBox {
  min-height: 200px;
  max-width: 600px;
}

.bold {
  font-weight: bold;
}

d-article, d-title  > * {
  grid-column: 2/14 !important;
}

d-article li {
  margin-bottom: 0 !important;
  margin-left: 0;
  padding-left: 0;
}

.infobox {
  border: 2px;
  border-style: solid;
  border-color: #454545;
  padding: 1em;
}

@media(min-width: 768px) {
  d-article {
    grid-template-columns: [gutter-left-start] 1fr [gutter-left-end column-left-start] 300px [column-left-end] 5px [column-right-start] 300px [column-right-end gutter-right-start] 1fr [gutter-right-end];
    grid-column-gap: 10px;
  }
  d-title  > * {
    grid-template-columns: [gutter-left-start] 0.5fr [gutter-left-end column-left-start] 150px [column-left-end] 50px [column-right-start] 100px [column-right-end gutter-right-start] 0.5fr [gutter-right-end] !important;
    grid-template-rows: [teaser-start] 150px 100px [teaser-end];
    grid-column: 2/5 !important;
  }

  .grid {
    grid-column-gap: 10px;
  }

  d-article > * {
    font-size: 1rem;
    grid-column: column-left-start/column-right-end !important;
  }

  .two-column {
    grid-auto-flow: column;
    grid-column: 1/-1 !important;
    display: grid;
    grid-template-columns: subgrid;
    grid-template-columns: [gutter-left-start] 1fr [gutter-left-end column-left-start] 300px [column-left-end] 5px [column-right-start] 300px [column-right-end gutter-right-start] 1fr [gutter-right-end];
    grid-column-gap:10px;
  }
  .item-gutter-left {
    width: 100%;
    justify-self: end;
    grid-column: column-left-start/column-left-end !important;
    align-self: center;
  }
  .item-gutter-right {
    width: 100%;
    grid-column: column-right-start/column-right-end !important;
    justify-self: start;
    align-self: center;
  }
  .column-left {
    grid-column: column-left-start/column-left-end !important;
  }
  .column-right {
    grid-column: column-right-start/column-right-end !important;
  }
  .plotlyDiv {
    width: 100%;
    max-height: 100%;
    min-height: 550px;
  }
  .plotlyDiv--selected {
    width: 100%;
    max-height: 100%;
    min-height: 550px;
  }
  d-title {
    grid-template-columns: [gutter-left-start] 0.5fr [gutter-left-end column-left-start] 300px [column-left-end] 50px [column-right-start] 190px [column-right-end gutter-right-start] 1fr [gutter-right-end] !important;
    grid-template-rows: [teaser-start] 200px 100px [teaser-end];
    grid-column-gap: 10px;
  }

  .teaser{
    grid-column: -2/-1 !important;
    grid-row: teaser-start/teaser-end;
  }

  .teaser img{
    max-width: 200px;
  }
}

@media(min-width: 1180px) {
  d-article {
    grid-auto-flow: column;
    grid-column: 1/-1 !important;
    display: grid;
    grid-template-columns: [gutter-left-start] 1fr [gutter-left-end column-left-start] 500px [column-left-end] 50px [column-right-start] 500px [column-right-end gutter-right-start] 1fr [gutter-right-end] !important;
    grid-column-gap: 10px;
    grid-column-gap:10px;
  }

  .grid {
    grid-column-gap: 10px;
  }
  d-article > * {
    font-size: 1rem;
    grid-column: column-left-start/column-right-end !important;
  }

  .two-column {
    grid-auto-flow: column;
    grid-column: 1/-1 !important;
    display: grid;
    grid-template-columns: subgrid;
    grid-template-columns: [gutter-left-start] 1fr [gutter-left-end column-left-start] 500px [column-left-end] 50px [column-right-start] 500px [column-right-end gutter-right-start] 1fr [gutter-right-end ] !important;
    grid-column-gap:10px;
  }
  .item-gutter-left {
    width: 100%;
    justify-self: end;
    grid-column: column-left-start/column-left-end !important;
    align-self: center;
  }
  .item-gutter-right {
    width: 100%;
    grid-column: column-right-start/column-right-end !important;
    justify-self: start;
    align-self: center;
  }
  .item-gutter-left img {
    float: right;
  }
  .item-gutter-right img {
    float: right;
  }

  .column-left {
    grid-column: column-left-start/column-left-end !important;
  }
  .column-right {
    grid-column: column-right-start/column-right-end !important;
  }
  .plotlyDiv {
    width: 100%;
    max-height: 100%;
    min-height: 550px;
  }
  .plotlyDiv--selected {
    width: 100%;
    max-height: 100%;
    min-height: 550px;
  }
  d-title {
    grid-template-columns: [gutter-left-start] 0.5fr [gutter-left-end column-left-start] 600px [column-left-end] 50px [column-right-start] 200px [column-right-end gutter-right-start] 0.5fr [gutter-right-end] !important;
    grid-template-rows: [teaser-start] 200px 100px [teaser-end];
  }

  .teaser{
    grid-column: -2/-1 !important;
    grid-row: teaser-start/teaser-end;
  }

  .teaser img{
    max-width: 300px;
  }

}

@media print,screen and (min-width: 1920px) {
  d-article, d-title {
    grid-template-columns: 50px [gutter-left-start] 1fr [gutter-left-end column-left-start] 500px [column-left-end] 50px [column-right-start] 500px [column-right-end gutter-right-start] 1fr [gutter-right-end] 50px  !important;
    grid-column-gap: 32px;
  }

  .grid {
    grid-column-gap: 32px;
  }

  d-article, d-title  > * {
    grid-column: column-left-start/column-right-end !important;
  }

  .two-column {
    grid-auto-flow: column;
    grid-column: 1/-1 !important;
    display: grid;
    /*grid-template-columns: subgrid;*/
    grid-template-columns: 50px [gutter-left-start] 1fr [gutter-left-end column-left-start] 500px [column-left-end] 50px [column-right-start] 500px [column-right-end gutter-right-start] 1fr [gutter-right-end] 50px  !important;
    grid-column-gap: 32px;
  }

  .column-left {
    grid-column: column-left-start / column-left-end !important;
  }

  .column-right {
    grid-column: column-right-start / column-right-end !important;
  }

  .item-gutter-left {
    grid-column: column-left-start / column-left-end !important;
  }
  .item-gutter-right {
    grid-column: column-right-start / column-right-end !important;
  }

  .item-gutter-right.image {
    grid-column: gutter-right-start/gutter-right-end !important;
  }
  .item-gutter-left.image {
    grid-column: gutter-left-start/gutter-left-end !important;
  }

  .item-gutter-left img {
    float: right;
  }
  .item-gutter-right img {
    float: left;
  }
  d-title {
    grid-template-rows: [teaser-start] 200px 100px [teaser-end];
  }

  .teaser{
    grid-column: -3/-2 !important;
    grid-row: teaser-start/teaser-end;
  }
}

.plotlyDiv {
  width: 100%!important;
  height: 100%!important;
  max-width: 100% !important;
  max-height: 100%!important;
}
.plotlyDiv.item-gutter-right {
  /*margin-top: 100px;*/
}

img
  {
    max-width: 100%;
}

img
.column-left, .colum-right,.item-gutter-left,.item-gutter-right  {
  max-width: 500px;
}

/*.main-svg {*/
/*  width:  100%;*/
/*  height:  100%;*/
/*}*/

.js-plotly-plot,
.plot-container {
  width: 100%;
  height:  100%;
  min-height: 450px;
  max-height: 450px;
}

.modebar-container {
  display: none;
}

.react-tabs li:last-child {
  float: right !important;
}
.scatterpts {
  transform-origin: -7px 0px;
  transform: scale(4.0);
}

.figcaption{

}

@media print{
  div.portrait, div.landscape {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
  }
  .parameterContainer{
    display: none;
  }
  @page {size: 800mm 1200mm}
  div#content-scroll {
    /*overflow-y: auto;*/
    height: 100%;
  }
  div#page {
    display: block;
    /*height: 100vh;*/
    height: 100%;
    width: 100%;
  }

}



